import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StockPredictor from './StockPredictor';
import Header from './Header';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Posts from './Posts';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    document.body.classList.toggle('dark-theme', !isDarkMode);
  };

  return (
    <Router>
      <div className={`container ${isDarkMode ? 'dark' : 'light'}`}>
        <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <Routes>
          <Route path="/" element={<About isDarkMode={isDarkMode}/>} />
          <Route path="/about" element={<About isDarkMode={isDarkMode}/>} />
          <Route path="/projects" element={<Projects isDarkMode={isDarkMode}/>} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/predictordashboard" element={<StockPredictor />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
