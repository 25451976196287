import React from 'react';

const Posts = () => {
  return (
    <section className="section">
        <h1>Posts</h1>
        <hr />
        <p>No posts yet</p>
    </section>
  );
}

export default Posts;
